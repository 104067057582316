/* alexandria-300 - latin_arabic */
@font-face {
    font-family: 'Alexandria';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/alexandria/alexandria-v1-latin_arabic-300.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/alexandria/alexandria-v1-latin_arabic-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/alexandria/alexandria-v1-latin_arabic-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-300.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/alexandria/alexandria-v1-latin_arabic-300.svg#Alexandria') format('svg'); /* Legacy iOS */
}

/* alexandria-regular - latin_arabic */
@font-face {
    font-family: 'Alexandria';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/alexandria/alexandria-v1-latin_arabic-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/alexandria/alexandria-v1-latin_arabic-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/alexandria/alexandria-v1-latin_arabic-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/alexandria/alexandria-v1-latin_arabic-regular.svg#Alexandria') format('svg'); /* Legacy iOS */
}

/* alexandria-500 - latin_arabic */
@font-face {
    font-family: 'Alexandria';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/alexandria/alexandria-v1-latin_arabic-500.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/alexandria/alexandria-v1-latin_arabic-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/alexandria/alexandria-v1-latin_arabic-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-500.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/alexandria/alexandria-v1-latin_arabic-500.svg#Alexandria') format('svg'); /* Legacy iOS */
}

/* alexandria-600 - latin_arabic */
@font-face {
    font-family: 'Alexandria';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/alexandria/alexandria-v1-latin_arabic-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/alexandria/alexandria-v1-latin_arabic-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/alexandria/alexandria-v1-latin_arabic-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/alexandria/alexandria-v1-latin_arabic-600.svg#Alexandria') format('svg'); /* Legacy iOS */
}

/* alexandria-700 - latin_arabic */
@font-face {
    font-family: 'Alexandria';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/alexandria/alexandria-v1-latin_arabic-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/alexandria/alexandria-v1-latin_arabic-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/alexandria/alexandria-v1-latin_arabic-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-700.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/alexandria/alexandria-v1-latin_arabic-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/alexandria/alexandria-v1-latin_arabic-700.svg#Alexandria') format('svg'); /* Legacy iOS */
}
